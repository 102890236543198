import React, { createContext, useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"

import { flexGap } from "../../theme"
import { Button } from "../atoms/Button"
import { Image } from "../atoms/Image"
import { Input } from "../atoms/Input"
import { Label } from "../atoms/Label"
import { LangContext } from "./WithLang"

import { navigate, useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"

export const TokenContext = createContext()
TokenContext.displayName = "TokenContext"

const FormRow = styled.div`
  display: flex;
  ${flexGap("20px")}
  flex-wrap: nowrap;
  margin-block: 0;
  align-items: center;

  & > * {
    margin-block-start: 0 !important;
    flex-shrink: 0 !important;
  }

  & > ${Input} {
    flex-shrink: 1 !important;
  }
`

const InvalidToken = styled((props) => {
  const lang = useContext(LangContext)
  const {
    noTokens,
    header: { logo },
  } = useStaticQuery(graphql`
    query {
      noTokens: allOtherPagesYaml(
        filter: { templateKey: { eq: "otherPages/noToken" } }
      ) {
        edges {
          node {
            meta {
              description
              title
            }
            lang
            title
            text
            tokenLabel
            buttonText
          }
        }
      }
      header: globalYaml(templateKey: { eq: "global/header" }) {
        logo {
          image {
            src {
              publicURL
            }
          }
        }
      }
    }
  `)
  const findLangNode = (lang) => (a) =>
    a.edges.find(({ node }) => node.lang === lang)?.node
  const noToken = findLangNode(lang)(noTokens) ?? findLangNode("en")(noTokens)

  const [token, setToken] = useState("")

  const location = useLocation()

  return (
    <div {...props}>
      <form
        onSubmit={(e) => {
          e.preventDefault()

          const search = new URLSearchParams(location.search)
          search.set("referral", token)

          navigate(`${location.pathname}?${search}`)
        }}
      >
        <Image image={logo.image} />
        <h1>{noToken.title}</h1>
        <p>{noToken.text}</p>

        <FormRow>
          <Label htmlFor="noToken-referralToken">{noToken.tokenLabel}</Label>
          <Input
            required
            id="noToken-referralToken"
            type="text"
            value={token}
            onChange={(v) => setToken(v)}
          />
          <Button type="submit">{noToken.buttonText}</Button>
        </FormRow>
      </form>
    </div>
  )
})`
  block-size: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & > form {
    block-size: 25%;
    inline-size: 50%;
  }
`

export const WithReferralToken = ({ ...props }) => {
  const [state, setState] = useState({ type: "notChecked" })
  const location = useLocation()

  useEffect(() => {
    ;(async () => {
      const referral = new URLSearchParams(location.search).get("referral")

      if (!referral) setState({ type: "noToken" })

      const res = await fetch(
        `${process.env.GATSBY_API_URL}/userManagement/api/preregister/allowed?referral=${referral}`,
      )

      setState(
        res.status >= 200 && res.status < 400
          ? { type: "success", value: referral }
          : res.status === 401
          ? { type: "invalid", value: referral }
          : { type: "error" },
      )
    })()
  }, [location])

  return {
    notChecked: () => null,
    noToken: () => <InvalidToken />,
    success: () => (
      <TokenContext.Provider value={state.value}>
        <div {...props} />
      </TokenContext.Provider>
    ),
    invalid: () => <InvalidToken />,
    error: () => <InvalidToken />,
  }[state.type]()
}
