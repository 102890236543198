import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

export const Input = styled(({ style, size, onChange, ...props }) => (
  <input {...props} onChange={(evt) => onChange(evt.target.value)} />
))`
  inline-size: 100%;
  padding-block: 22px;
  padding-inline: 28px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 3px 8px rgba(42, 66, 90, 0.02),
    0px 32px 48px rgba(42, 66, 90, 0.05);
  @media (min-width: 768px) {
    box-size: auto;
  }
`
