import { css } from "@emotion/react"

import { Getter, Lens } from "monocle-ts"

export const toCss = new Getter((c) => c.toString())

export const theme = Lens.fromProp()("theme")

export const colours = Lens.fromProp()("colours")

export const primary = Lens.fromProp()("primary")
export const secondary = Lens.fromProp()("secondary")
export const dark = Lens.fromProp()("dark")
export const success = Lens.fromProp()("success")
export const warning = Lens.fromProp()("warning")
export const lightBlue = Lens.fromProp()("lightBlue")
export const darkBlue = Lens.fromProp()("darkBlue")
export const greyBlue = Lens.fromProp()("greyBlue")

export const setA = (a) => new Getter((c) => c.setAlpha(a))

export const maxAppWidth = Lens.fromPath()(["theme", "maxAppWidth"])

export const colour = (c) => colours.composeLens(c)
export const colourCss = (c) => colour(c).composeGetter(toCss)

export const themeColour = (c) => theme.composeLens(colour(c))
export const themeColourCss = (c) => theme.composeGetter(colourCss(c))

export const inlineCssFunction = (v, props) =>
  typeof v === "function" ? v(props) : v

export const background = (normal, header, highlight) => (props) =>
  css`
    color: ${inlineCssFunction(normal, props)};

    h1,
    h2,
    h3,
    h4 {
      color: ${inlineCssFunction(normal, header)};
    }

    .highlight {
      font-weight: bold;
      color: ${inlineCssFunction(normal, highlight)};
    }
  `

export const defaultBackground = background(
  colourCss(dark).get,
  "#000",
  colourCss(primary).get,
)
export const primaryBackground = background(
  "#fff",
  "#000",
  colourCss(secondary).get,
)

export const flexGap = (size) => css`
  display: inline-flex;
  flex-wrap: wrap;
  margin-block-start: calc(${size} * -1);
  margin-inline-start: calc(${size} * -1);
  inline-size: calc(100% + 12px);

  & > * {
    margin-block-start: ${size} !important;
    margin-inline-start: ${size} !important;
  }
`

export const gap = (size) => css`
  margin-inline-end: ${size};

  &:last-child {
    margin-inline-end: 0;
  }
`
