import React, { useMemo } from "react"
import { css, Global, ThemeProvider as ThemeProvider_ } from "@emotion/react"

import montserratBold from "../../../static/img/Montserrat-Bold.woff2"
import montserratRegular from "../../../static/img/Montserrat-Regular.woff2"
import { colourCss, defaultBackground, primary } from "../../theme"

import colour from "color"

const colours = {
  primary: colour.rgb(12, 122, 233),
  secondary: colour.rgb(240, 198, 71),
  dark: colour.rgb(42, 66, 90),
  success: colour.rgb(17, 226, 151),
  warning: colour.rgb(226, 164, 17),
  lightBlue: colour.rgb(18, 92, 223),
  darkBlue: colour.rgb(0, 172, 250),
  greyBlue: colour.rgb(44, 79, 107),
  gold: colour.rgb(238, 188, 153),
  blue: colour.rgb(71, 176, 234),
  yellow: colour.rgb(255, 215, 100),
}

export const ThemeProvider = ({ theme, children, ...props }) => {
  const theme_ = useMemo(() =>
    Object.assign({ colours, maxAppWidth: "1280px" }, theme),
  )

  return (
    <ThemeProvider_ theme={theme_} {...props}>
      <Global
        styles={css`
          @font-face {
            font-family: "Montserrat";
            font-weight: normal;
            src: local("Montserrat-Regular"),
              url(${montserratRegular}) format("woff2");
          }

          @font-face {
            font-family: "Montserrat";
            font-weight: bold;
            src: local("Montserrat-Bold"),
              url(${montserratBold}) format("woff2");
          }

          body {
            margin: 0px;
            background-color: white;
            font-size: 16px;
            ${defaultBackground}
          }

          * {
            all: revert;
            box-sizing: border-box;
            font-family: "Montserrat", sans-serif;
          }

          button,
          a,
          [role="button"],
          input[type="checkbox"],
          label[for] {
            cursor: pointer;
            user-select: none;
          }

          a {
            color: ${colourCss(primary).get(theme_)};
            font-weight: bold;
            text-decoration: none;
          }

          h3 {
            font-size: 1.25em;
          }

          iframe {
            border: none;
          }
        `}
      />{" "}
      {children}
    </ThemeProvider_>
  )
}
