import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { colourCss, dark, secondary, themeColourCss } from "../../theme"
import { Link } from "../atoms/Link"

const buttonStyle = ({ style, size, theme }) =>
  css`
    all: unset;
    cursor: pointer;
    user-select: none;
    inline-size: 100%;
    box-sizing: border-box;
    border: 1px solid ${colourCss(secondary).get(theme)};
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
    font-size: 1em;

    ${style === "solid" &&
    css`
      color: ${colourCss(dark).get(theme)};
      background-color: ${colourCss(secondary).get(theme)};
    `}

    ${{
      small: css`
        font-size: 0.875em;
        padding: 10px 22px;
      `,
      normal: css`
        padding: 22px 28px;
      `,
    }[size]}

    @media (min-width: 768px) {
      inline-size: auto;
      block-size: auto;
    }
  `

export const Button = styled(({ style, size, ...props }) => (
  <button {...props} />
))`
  ${buttonStyle}
`
Button.defaultProps = {
  type: "button",
  style: "solid",
  size: "normal",
}

export const SubmitButton = (props) => <Button {...props} />
SubmitButton.defaultProps = { type: "submit" }

export const RouteButton = styled(({ style, size, ...props }) => (
  <Link {...{ ...props }} />
))`
  ${buttonStyle}
`
RouteButton.defaultProps = {
  style: "solid",
  size: "normal",
}
