import React, { useContext, useEffect, useMemo } from "react"

import { TokenContext } from "../organisms/WithReferralToken"

import { useLocation } from "@reach/router"
import { Link as Link_ } from "gatsby"

export const Link = ({ to, lang, ...props }) => {
  const token = useContext(TokenContext)
  const location = useLocation()
  const lang_ = lang ?? location.pathname.slice(1, 3)
  console.log("to", to)

  const to_ = useMemo(() => {
    const toUrl = new URL(to, location.href)
    const search = new URLSearchParams(toUrl.search)
    search.set("referral", token)
    toUrl.search = search.toString()
    toUrl.pathname = `/${lang_}${toUrl.pathname}`
    return `${toUrl.pathname}${toUrl.search ?? ""}${toUrl.hash ?? ""}`
  }, [to, token])

  return <Link_ to={to_} {...props} />
}
