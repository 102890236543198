module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/builds/fitnesspilot/fitnesspilot/clients/preregistration/static/img/icon.png","background_color":"#ffffff","theme_color":"#3ac6ef","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9202b8a8fce089f0491db62c033f219d"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-static-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"./src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
