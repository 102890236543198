import React from "react"

import { ThemeProvider } from "./src/components/atoms/ThemeProvider"
import { WithLang } from "./src/components/organisms/WithLang"
import { WithReferralToken } from "./src/components/organisms/WithReferralToken"

export const wrapPageElement = ({ element, props }) => (
  <WithLang>
    <WithReferralToken {...props}>{element}</WithReferralToken>
  </WithLang>
)

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
