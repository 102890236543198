import React, { createContext, useEffect, useState } from "react"
import styled from "@emotion/styled"

import { flexGap } from "../../theme"
import { Button } from "../atoms/Button"
import { Image } from "../atoms/Image"
import { Input } from "../atoms/Input"
import { Label } from "../atoms/Label"

import { navigate, useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"

export const LangContext = createContext("en")
LangContext.displayName = "LangContext"

export const WithLang = ({ ...props }) => {
  const location = useLocation()

  return (
    <LangContext.Provider
      value={location.pathname.slice(1, 3) || "en"}
      {...props}
    />
  )
}
